import { buildCollection } from "@firecms/core";
import { BlogEntry } from "../types";

export const blogEntriesCollection = buildCollection<BlogEntry>({
	name: "Blog Entries",
	singularName: "Blog Entry",
	id: "blog",
	path: "blog",
	icon: "article",
	properties: {
		title: {
			dataType: "string",
			name: "Title",
			description: "Title of the blog entry",
			validation: {
				required: true,
			},
		},
		date: {
			dataType: "date",
			name: "Date",
			description: "Date of the blog entry",
			validation: {
				required: true,
			},
			autoValue: "on_create",
			editable: false,
			readOnly: true,
		},
		content: {
			dataType: "string",
			name: "Content",
			description: "Content of the blog entry",
			markdown: true,
			storage: {
				storagePath: "images/blog",
				acceptedFiles: ["image/*"],
				storeUrl: true,
			},
		},
		img: {
			dataType: "string",
			name: "Image",
			description: "Image of the blog entry",
			storage: {
				storagePath: "Images",
				storeUrl: true,
				acceptedFiles: ["image/*"],
			},
		},
		publish: {
			dataType: "boolean",
			name: "Publish",
			description: "Publish the blog entry",
			defaultValue: false,
		},
	},
});
